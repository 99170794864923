import spacetime from "spacetime";
import {RequestOptionEnum, StatusEnum} from "./options";
import gsap from 'gsap'

export function getComponents(defaultComponents, overrides) {
    return Object.keys(defaultComponents).reduce((acc, name) => {
        const override = overrides[name] || {}
        acc[name] = {
            component: override.component || defaultComponents[name],
            props: { $style: override.style, ...override.props },
        }
        return acc
    }, {})
}

export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
          }
        : null
}

export function sinceFromNow(datetime) {
    const now = spacetime.now()
    const dt = spacetime(datetime)
    if (!dt.isValid()) {
        return null
    }
    const [num, unit] = now.since(dt).rounded.split(' ')
    switch (unit) {
        case 'second':
        case 'seconds':
            return `${num}초 전`
        case 'minute':
        case 'minutes':
            return `${num}분 전`
        case 'hour':
        case 'hours':
            return `${num}시간 전`
        case 'day':
        case 'days':
            return `${num}일 전`
        case 'month':
        case 'months':
            return `${num}개월 전`
        case 'year':
        case 'years':
            return `${num}년 전`
        default:
            if (num === 'now') {
                return '방금 전'
            }
    }
}

export function flattenFormData(data) {
    let newData = Object.assign({}, data)
    for (let key in newData) {
        let value = newData[key]
        if (Array.isArray(value)) {
            value = value.map((row) => row.value)
            newData[key] = value
        }
    }
    return newData
}

export function getQueryVariable(variable) {
    const query = window.location.search.substring(1)
    let vars = query.split('&')
    for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=')
        if (decodeURIComponent(pair[0]) === variable) {
            return decodeURIComponent(pair[1])
        }
    }
}

export function parseProjectCategory(val) {
    const categories = Object.values(RequestOptionEnum.project.tasks).flat(1)
    const filtered = categories.filter((row) => row.value === val)
    return filtered.length ? filtered[0].label : null
}

export function parseProjectType (val) {
    const types = Object.values(RequestOptionEnum.project.range);
    const filtered = types.filter((row) => row.value === val)
    return filtered.length ? filtered[0].label : null
}

export function parseGroup (val) {
    switch (val) {
        case 'project':
            return '프로젝트 의뢰'
        default:
            return '기타 의뢰';
    }
}

export function parseEtcRequestType (val) {
    const types = Object.values(RequestOptionEnum.etc);
    const filtered = types.filter((row) => row.value === val)
    return filtered.length ? filtered[0].label : null
}

export const authStorage = {
    getAccessToken: () => JSON.parse(window.localStorage.getItem('access')),
    getRefreshToken: () => JSON.parse(window.localStorage.getItem('refresh')),
    setTokens: (token) => {
        window.localStorage.setItem('access', JSON.stringify(token.access))
        window.localStorage.setItem('refresh', JSON.stringify(token.refresh))
    },
    setUpdateAccessToken: (token) => window.localStorage.setItem('access', JSON.stringify(token.access)),
    clearTokens: () => {
        window.localStorage.removeItem('access')
        window.localStorage.removeItem('refresh')
    },
}


export function humanizeStatus (statusKey) {
    return StatusEnum[statusKey] || "-";
}

export const colorAccept = '#00a23d';
export const colorReject = '#444444';
export const colorCompleted = '#2061e3';
export const colorCancelled = '#ccc';

export function humanizeBudget (val) {
    const budgets = Object.values(RequestOptionEnum.project.budget);
    return budgets.find(row => row.value === val)?.label;
}

export const statusToStep = (status) => {
    const idx = Object.keys(StatusEnum).indexOf(status);
    if (idx < 3) {
        return idx;
    }
    return 3
}

export function debounce (callback, duration) {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => callback(...args), duration);
    };
}

export function countAnimation (el, countTo, delay=0) {
    if(!el.classList.contains('count')){
        el = el.querySelector('.count');
    }

    el.count = 0;

    gsap.to(el, {
        count:countTo,
        duration:1,
        ease:'power2.out',
        delay,
        onUpdate(){
            el.innerHTML = numberWithCommas(el.count.toFixed(0));
        }
    })
}

export function countFadeAnimation (el, delay=0) {
    if(!el.classList.contains('count')){
        el = el.querySelector('.count');
    }

    gsap.to(el, {
        autoAlpha:1,
        duration:0.6,
        ease:'power1.inOut',
        delay,
    })
}